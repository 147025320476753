import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getDayFromBegin, getLanguageEntry, parsePriceInput, priceToString} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropdown from "../input/Dropdown";
import FetchButton from "../buttons/FetchButton";
import Calendar from "../Calendar";
import UnitInputField from "../input/UnitInputField";
import {
    addCurrentPricePeriod,
    addCurrentPriceTemplate, deleteCurrentPricePeriod,
    deleteCurrentPriceTemplate
} from "../../utils/RESTInterface";
import {UnitType} from "../../utils/Types.ts";

class CurrentPriceModal extends React.Component {

    WORKDAYS_REGEX = '12345';
    WEEKEND_REGEX = '67';

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            advert: null,
            room_id: null,
            current_price: null,
            type: null,
            initialDate: null,
            selected_template: null,
            repeat: "0",
            show_creator: false,
            show_editor: true,
            error: null,
        }
        this.calendar = React.createRef();
        this.template_creator = React.createRef();
        this.template_editor = React.createRef();
        this.simple_price = React.createRef();
        this.template_selector = React.createRef();
    }

    render() {
        return (
            <Modal className={"cp-modal landlord"} show={this.state.show} size="lg" backdrop="static" keyboard={false}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("components>current_price_modal>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.saving_template ||
                        this.state.deleting_template ||
                        this.state.saving || this.state.deleting}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.type === null &&
                        <div className="desktop-submenu-grid" style={{justifyContent: "center"}}>
                            <div className="desktop-submenu-tile" onClick={() => {
                                this.setState({type: "simple"}, () => { this.setInitialDates() })
                            }}>
                                <div className="tile-text-container">
                                    {
                                        getLanguageEntry("components>current_price_modal>simple_current_price")
                                    }
                                </div>
                            </div>
                            <div className="desktop-submenu-tile" onClick={() => {
                                this.setState({type: "template"}, () => { this.setInitialDates() })
                            }}>
                                <div className="tile-text-container">
                                    {
                                        getLanguageEntry("components>current_price_modal>template_current_price")
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.advert && this.state.type !== null &&
                        <div style={{display: "grid", gap: "20px", justifyItems: "center"}}>
                            <div className="description-container">
                                {
                                    getLanguageEntry("components>current_price_modal>" + this.state.type + "_description")
                                }
                            </div>
                            {
                                this.state.type === "simple" &&
                                <div className="horizontal-form-group">
                                    <label className="description-container"
                                           htmlFor="template-price">
                                        {getLanguageEntry("components>current_price_modal>price_label")}
                                    </label>
                                    <UnitInputField id="template-price" required={true} type={UnitType.currency}
                                                    ref={this.simple_price}
                                                    name="price" onChange={() => {this.clearError()}} />
                                </div>
                            }
                            {
                                this.state.type === "template" &&
                                <div className="horizontal-form-group wrap">
                                    {
                                        this.state.advert.current_price_templates &&
                                        <Dropdown items={this.getTemplateItems()} name="block_template"
                                                  initialValue={this.state.selected_template} required={true}
                                                  comparator={(i1, i2) => i1 && i1.id === i2.id}
                                                  ref={this.template_selector}
                                                  onChange={(_, v) => {this.setState({
                                                      selected_template: v,
                                                      show_creator: false,
                                                      show_editor: true,
                                                      error: null
                                                  })}}/>
                                    }
                                    {
                                        !this.state.show_creator &&
                                        <button className="outline-button black" onClick={() => {this.setState({
                                            show_creator: true,
                                            show_editor: false,
                                            selected_template: null,
                                            error: null
                                        })}}>
                                            <FontAwesomeIcon icon={["fal", "plus"]}/>
                                            <span>{getLanguageEntry("components>current_price_modal>new_template")}</span>
                                        </button>
                                    }
                                </div>
                            }
                            {
                                this.state.type === "template" && this.state.show_creator && !this.state.show_editor &&
                                <div className="horizontal-form-group">
                                    <form ref={this.template_creator} className="horizontal-form-group wrap"
                                          onSubmit={(e) => {this.onSaveTemplate(e)}}
                                          style={{justifyContent: "center"}}>
                                        <div className="horizontal-form-group">
                                            <label className="description-container" htmlFor="template-description">
                                                {getLanguageEntry("components>current_price_modal>description_label")}
                                            </label>
                                            <input id="template-description" maxLength={20} required={true}
                                                   onChange={() => {this.clearError()}} name="description"
                                                   placeholder={getLanguageEntry("components>current_price_modal>description_placeholder")}/>
                                        </div>
                                        <div className="horizontal-form-group">
                                            <label className="description-container"
                                                   htmlFor="template-price">
                                                {getLanguageEntry("components>current_price_modal>price_label")}
                                            </label>
                                            <UnitInputField id="template-price" required={true} type={UnitType.currency}
                                                            name="price" onChange={() => {this.clearError()}} />
                                        </div>
                                        <FetchButton className="simple-icon-button outline-button black"
                                                     disabled={this.state.saving_template ||
                                                         this.state.deleting_template ||
                                                         this.state.saving || this.state.deleting}
                                                     loading={this.state.saving_template} type="submit">
                                            <FontAwesomeIcon icon={["fal", "save"]}/>
                                        </FetchButton>
                                        <button className="simple-icon-button outline-button black"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                    show_creator: false,
                                                    error: null}) }}>
                                            <FontAwesomeIcon icon={["fal", "times"]}/>
                                        </button>
                                    </form>
                                </div>
                            }
                            {
                                this.state.type === "template" && this.state.show_editor && this.state.selected_template &&
                                <div className="horizontal-form-group">
                                    <form ref={this.template_editor} className="horizontal-form-group wrap"
                                          onSubmit={(e) => {this.onSaveTemplate(e, this.state.selected_template)}}
                                          style={{justifyContent: "center"}}>
                                        <div className="horizontal-form-group">
                                            <label className="description-container" htmlFor="template-description">
                                                {getLanguageEntry("components>current_price_modal>description_label")}
                                            </label>
                                            <input id="template-description" maxLength={20} required={true} name="description"
                                                   onChange={() => {this.clearError()}} defaultValue={this.state.selected_template.description}
                                                   placeholder={getLanguageEntry("components>current_price_modal>description_placeholder")}/>
                                        </div>
                                        <div className="horizontal-form-group">
                                            <label className="description-container"
                                                   htmlFor="template-price">
                                                {getLanguageEntry("components>current_price_modal>price_label")}
                                            </label>
                                            <UnitInputField initialValue={this.state.selected_template.price / 100}
                                                            required={true} type={UnitType.currency} name="price"
                                                            id="template-price" onChange={() => {this.clearError()}} />
                                        </div>
                                        <FetchButton className="simple-icon-button outline-button black"
                                                     disabled={this.state.saving_template ||
                                                         this.state.deleting_template ||
                                                         this.state.saving || this.state.deleting}
                                                     loading={this.state.saving_template} type="submit">
                                            <FontAwesomeIcon icon={["fal", "save"]}/>
                                        </FetchButton>
                                        <FetchButton className="simple-icon-button outline-button black"
                                                     onClick={(e) => { this.onDeleteTemplate(e) }}
                                                     disabled={this.state.saving_template ||
                                                         this.state.deleting_template ||
                                                         this.state.saving || this.state.deleting}
                                                     loading={this.state.deleting_template}>
                                            <FontAwesomeIcon icon={["fal", "trash"]}/>
                                        </FetchButton>
                                    </form>
                                </div>
                            }
                            {
                                this.state.error &&
                                <div className="description-container">
                                    <FontAwesomeIcon icon={["fal", "exclamation-circle"]} style={{color: "red", marginRight: "3px"}}/>
                                    {this.state.error}
                                </div>
                            }
                            <div style={{display: "grid", gap: "20px"}}>
                                <div className="horizontal-form-group wrap" style={{justifyContent: "center"}}>
                                    {
                                        Array.from(Array(7)).map((_, i) => {
                                            let value = "" + (i + 1);
                                            return <div className="accent-radio-button" key={"day-" + i}>
                                                <input type="checkbox" id={"day-" + i} autoComplete="off" value={value}
                                                       checked={this.state.repeat && this.state.repeat.includes(value)}
                                                       readOnly={true}
                                                       onClick={(e) => {this.setRepeat(e, true)}} />
                                                <label htmlFor={"day-" + i} style={{textTransform: "lowercase", fontSize: "12px"}}>
                                                    {
                                                        getLanguageEntry("components>current_price_modal>week_days>" + i)
                                                    }
                                                </label>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="horizontal-form-group wrap" style={{justifyContent: "center"}}>
                                    <div className="accent-radio-button">
                                        <input type="checkbox" id="no-period-repeat" autoComplete="off" value={"0"}
                                               checked={this.state.repeat === "0"} readOnly={true}
                                               onClick={(e) => {this.setRepeat(e, false)}} />
                                        <label htmlFor="no-period-repeat" style={{textTransform: "initial", fontSize: "12px"}}>
                                            {
                                                getLanguageEntry("components>current_price_modal>no_repeat")
                                            }
                                        </label>
                                    </div>
                                    <div className="accent-radio-button">
                                        <input type="checkbox" id="workdays" autoComplete="off" value={this.WORKDAYS_REGEX}
                                               checked={this.state.repeat === this.WORKDAYS_REGEX} readOnly={true}
                                               onClick={(e) => {this.setRepeat(e, false)}} />
                                        <label htmlFor="workdays" style={{textTransform: "initial", fontSize: "12px"}}>
                                            {
                                                getLanguageEntry("components>current_price_modal>workdays")
                                            }
                                        </label>
                                    </div>
                                    <div className="accent-radio-button">
                                        <input type="checkbox" id="weekend" value={this.WEEKEND_REGEX}
                                               checked={this.state.repeat === this.WEEKEND_REGEX} readOnly={true}
                                               onClick={(e) => {this.setRepeat(e, false)}} />
                                        <label htmlFor="weekend" style={{textTransform: "initial", fontSize: "12px"}}>
                                            {
                                                getLanguageEntry("components>current_price_modal>weekend")
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <Calendar ref={this.calendar} hideOnBlur={false} useFooter={false} countDays={true}
                                      markDays={this.state.repeat} classList={["always-show"]} />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.current_price &&
                        <FetchButton loading={this.state.deleting} loadingText={"general>deleting"} className={"outline-button black"}
                                     disabled={this.state.saving_template || this.state.deleting_template || this.state.saving || this.state.deleting}
                                     onClick={() => {this.onDeleteCurrentPrice()}}>
                            <FontAwesomeIcon icon={["fal", "trash"]}/>
                            <span>{getLanguageEntry("general>delete")}</span>
                        </FetchButton>
                    }
                    <FetchButton loading={this.state.saving} loadingText={"general>saving"} className={"outline-button black"}
                                 disabled={this.state.saving_template || this.state.deleting_template || this.state.saving || this.state.deleting}
                                 onClick={(e) => { this.onSaveCurrentPrice(e) }}>
                        <FontAwesomeIcon icon={["fal", "save"]}/>
                        <span>{getLanguageEntry("general>save")}</span>
                    </FetchButton>
                </Modal.Footer>
            </Modal>
        )
    }

    show(advert, roomID, currentPrice, date) {
        this.setState({
            show: true,
            advert: advert,
            room_id: roomID ?? null,
            current_price: currentPrice,
            type: !currentPrice ? null : (currentPrice.template_id ? "template" : "simple"),
            initialDate: date,
            selected_template: currentPrice && currentPrice.template_id && advert.current_price_templates ?
                advert.current_price_templates.filter(t => t.id === currentPrice.template_id)[0] : null,
            repeat: currentPrice && currentPrice.repeat ? currentPrice.repeat : "0",
            show_creator: false,
            show_editor: true,
            error: null
        }, () => { this.setInitialDates() });
    }

    hide() {
        this.setState({
            show: false,
            booking: null,
            cancellation_result: null,
            cancelling: false
        })
    }

    getTemplateItems() {
        let items = [];
        if (this.state.advert && this.state.advert.current_price_templates) {
            for (const template of this.state.advert.current_price_templates) {
                items.push({ value: template, label: template.description + " (" + priceToString(template.price) + ")" })
            }
        }
        return items;
    }

    setInitialDates() {
        if (this.calendar.current) {
            if (this.state.current_price) {
                let start = getDayFromBegin(new Date(this.state.current_price.start));
                let end = this.state.current_price.end === 0 ? null : getDayFromBegin(new Date(this.state.current_price.end));
                this.calendar.current.setDates(start, end, false, false, "start");
            }
            else {
                this.calendar.current.setDates(getDayFromBegin(this.state.initialDate), null, false, true, "start");
            }
        }
    }

    clearError() {
        if (this.state.error) {
            this.setState({error: null})
        }
    }

    onSaveTemplate(e, template) {
        e.preventDefault();
        let formData = new FormData((template ? this.template_editor.current : this.template_creator.current));
        let description = formData.get("description");
        let price = parsePriceInput(formData.get("price"));
        if (!price.valid) {
            this.setState({
                error: getLanguageEntry("components>current_price_modal>invalid_price_error")});
            return;
        }
        if (this.state.advert.current_price_templates) {
            for (const otherTemplate of this.state.advert.current_price_templates) {
                if ((!template || template.id !== otherTemplate.id) && otherTemplate.description.toLowerCase() === description.toLowerCase()) {
                    this.setState({
                        error: getLanguageEntry("components>current_price_modal>same_name_error")});
                    return;
                }
            }
        }
        if (!template || template.description !== description || template.price !== price.costs_cent) {
            this.setState({
                saving_template: true,
                error: null
            });
            let that = this;
            addCurrentPriceTemplate(this.state.advert.id, template ? template.id : null, description, price.costs_cent,
                (response) => {
                let state = {
                    saving: false,
                    deleting: false,
                    saving_template: false,
                    deleting_template: false
                };
                if (response.errorCode) {
                    console.error(response.errorCode + ": " + response.message);
                    that.setState(state);
                }
                else {
                    if (!template) {
                        template = response.data.current_price_templates
                            .filter(t => t.id === response.id)[0];
                    }
                    state.advert = response.data;
                    state.selected_template = template;
                    state.show_editor = true;
                    state.show_creator = false;
                    that.setState(state);
                    that.props.onAdvertUpdate?.();
                }
            });
        }
        else {
            this.setState({
                show_editor: false,
                selected_template: template.id,
                error: null
            })
        }
    }

    onDeleteTemplate(e) {
        e.preventDefault();
        if (!this.state.selected_template) {
            this.setState({
                show_creator: false,
                show_editor: false,
                error: null
            });
        }
        else if (this.state.advert.current_price_templates) {
            let templateList = this.state.advert.current_price_templates.filter(b => b.id !== this.state.selected_template.id);
            if (templateList.length !== this.state.advert.current_price_templates.length) {
                this.setState({
                    deleting_template: true,
                    error: null
                });
                let that = this;
                deleteCurrentPriceTemplate(this.state.advert.id, this.state.selected_template.id, (response) => {
                    let state = {
                        saving: false,
                        deleting: false,
                        saving_template: false,
                        deleting_template: false
                    };
                    if (response.errorCode) {
                        console.error(response.errorCode + ": " + response.message);
                        that.setState(state);
                    }
                    else {
                        state.advert = response.data;
                        state.selected_template = null;
                        state.show_editor = false;
                        that.setState(state);
                        that.props.onAdvertUpdate?.();
                    }
                });
            }
        }
    }

    onSaveCurrentPrice(e) {
        e.preventDefault();
        let start = this.calendar.current.getSelectedStart();
        if (!start) {
            this.calendar.current.onStartClick();
            this.setState({error: getLanguageEntry("components>current_price_modal>no_start_error")});
            return;
        }
        let template; let price;
        if (this.state.type === "template") {
            template = this.template_selector.current.value;
            if (!template) {
                this.setState({error: getLanguageEntry("components>current_price_modal>no_template_selected_error")});
                return;
            }
            price = template.price;
        }
        else {
            price = parsePriceInput(this.simple_price.current.value);
            if (!price.valid) {
                this.setState({error: getLanguageEntry("components>current_price_modal>invalid_price_error")});
                return;
            }
            price = price.costs_cent;
        }
        let housingID = this.getHousingID();
        let periodID = this.state.current_price ? this.state.current_price.id : null;
        let end = this.calendar.current.getSelectedEnd() ? this.calendar.current.getSelectedEnd().getTime() : null;
        let templateID = template ? template.id : null;
        this.setState({saving: true});
        let that = this;
        addCurrentPricePeriod(housingID, periodID, start.getTime(), end, templateID, price, this.state.repeat, (response) => {
            that.handleResponse(response)
        });
    }

    onDeleteCurrentPrice() {
        if (this.state.current_price) {
            this.setState({deleting: true});
            let that = this;
            deleteCurrentPricePeriod(this.getHousingID(), this.state.current_price.id, (response) => {
                that.handleResponse(response)
            })
        }
    }

    setRepeat(e, add) {
        let repeat = this.state.repeat;
        if (e.target.checked) {
            if (repeat && add) {
                repeat = (repeat.replace("0", "") + e.target.value).split("").sort().join("");
            }
            else {
                repeat = e.target.value;
            }
            this.setState({repeat: repeat});
        }
        else {
            if (repeat) {
                repeat = repeat.replace(e.target.value, "");
                if (repeat.length === 0) {
                    repeat = "0"
                }
                this.setState({repeat: repeat});
            }
        }

    }

    getHousingID() {
        let housingID = this.state.advert.id;
        if (this.state.room_id) {
            housingID += "SP" + this.state.room_id;
        }
        return housingID;
    }

    handleResponse(response) {
        let state = {
            saving: false,
            deleting: false,
            saving_template: false,
            deleting_template: false
        };
        if (response.errorCode) {
            state.error = response.errorCode + ": " + response.message;
        }
        else if (response.data) {
            this.props.onAdvertUpdate?.(response.data);
            state.show = false;
        }
        this.setState(state);
    }

}

CurrentPriceModal.propTypes = {
    onAdvertUpdate: PropTypes.func.isRequired,
}
CurrentPriceModal.defaultProps = {}
export default CurrentPriceModal;